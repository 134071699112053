<template>
  <Dialog :commonDialog.sync="dialog" :dialogWidth.sync="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex> Manage Sub Category </v-flex>
        <v-flex class="text-right">
          <v-btn small class="mx-4" v-on:click="createProductCategory">
            <span class="font-size-16 font-weight-600">Add Sub Category</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="position: static"
      >
        <v-container class="py-0">
          <v-row class="py-0">
            <v-col md="12" class="py-0">
              <table class="width-100">
                <tbody>
                  <tr v-for="(type, index) in subCategoryList" :key="index">
                    <td>
                      <v-autocomplete
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="categoryList"
                        label="Category"
                        solo
                        flat
                        item-text="text"
                        item-value="id"
                        class="px-4 pt-5"
                        v-model.trim="type.category"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Category(s) Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Category"
                        solo
                        flat
                        class="px-4"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteProductCategory(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateProductSubCategory"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PATCH } from "@/core/services/store/request.module";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    category: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    subcategory: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
  },
  watch: {
    category: {
      deep: true,
      immediate: true,
      handler(param) {
        this.categoryList = param;
      },
    },
    subcategory: {
      deep: true,
      immediate: true,
      handler(param) {
        this.subCategoryList = param;
      },
    },
  },
  data: () => {
    return {
      pageLoading: false,
      categoryList: [],
      subCategoryList: [
        {
          text: null,
        },
      ],
    };
  },
  methods: {
    updateProductSubCategory() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/product-sub-category",
          data: { product_subcategory: _this.subCategoryList },
        })
        .then(() => {
          _this.$emit("close-dialog", true);
          _this.$emit("get-product-category", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createProductCategory() {
      this.subCategoryList.push({
        text: null,
        category: null,
      });
    },
    deleteProductCategory(index) {
      if (this.subCategoryList.length > 1) {
        this.subCategoryList.splice(index, 1);
      }
    },
  },
  components: {
    Dialog,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
